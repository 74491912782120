import React, { useState,useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Spinner } from "flowbite-react";
import { register } from "../APIservice/apiService";
import { nameCharLimit } from "../CharLimit";
import { UserContext } from "../context/UserContext";

const SignUp = () => {
  const navigate = useNavigate();
  const { UserName, updateUser } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};

    // Validate fullname
    if (!formData.fullname) {
      newErrors.fullname = "Full Name is required";
    } else if (formData.fullname.length > nameCharLimit) {
      newErrors.fullname = `Name cannot exceed ${nameCharLimit} characters`;
    }

    // Validate email
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!validateEmail(formData.email)) {
      newErrors.email = "Please enter a valid email";
    }

    // Validate password
    if (!formData.password) {
      newErrors.password = "Password is required";
    } else if (formData.password.length < 8 || formData.password.length > 16) {
      newErrors.password = "Password must be between 8 and 16 characters";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setLoading(true);
    try {
      const response = await register(formData.fullname, formData.email, formData.password);
      if (response && response.data) {
        if (response.data?.error !== "Email already exists") {
          navigate("/dashboard");
          updateUser({
            name: response.data.name,
            email: response.data.email,
            credits: response.data.credits,
            plan: response.data.plan,
          });
        }
      }
    } catch (error) {
      console.error("Signup error:", error);
      if (error.response && error.response.status === 409) {
        setErrors({ email: "Email is already in use" });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-white flex">
      {/* Container */}
      <div className="flex flex-row w-full">
        {/* Sidebar */}
        <div className="hidden lg:flex flex-col justify-between bg-black text-white lg:p-8 xl:p-12 lg:max-w-sm xl:max-w-lg">
          <div className="flex items-center justify-start space-x-3">
          <img
              src={require("../assets/3.png")}
              alt="logo"
              className="w-75 h-10 cursor-pointer"
              onClick={() => window.location.href = process.env.REACT_APP_REDIRECT_URL}
            />
          </div>
          <div className="space-y-5">
            <h1 className="lg:text-3xl xl:text-5xl xl:leading-snug font-extrabold">
              Create stunning visuals with our easy-to-use tools
            </h1>
            <p className="text-lg">Already have an account?</p>
            <button
              onClick={() => navigate("/login")}
              className="inline-block flex-none px-4 py-3 border-2 rounded-lg font-medium border-black bg-white text-black hover:bg-[#4c6ee2] hover:text-white duration-700"
            >
              Login here
            </button>
          </div>
          <p className="font-medium">© 2024 – Imageeazy</p>
        </div>

        {/* Sign Up Form */}
        <div className="flex flex-1 flex-col items-center justify-center relative">
          {/* Mobile Logo Header */}
          <div className="flex lg:hidden justify-between items-center w-full py-4 px-10">
            <div className="flex items-center justify-start space-x-3">
              {/* <img
                src={require("../assets/f3.png")}
                alt="logo"
                className="w-20 "
              /> */}
            </div>
            {/* <div className="flex items-center space-x-2">
              <span>Already a member? </span>
              <button
                onClick={() => navigate("/login")}
                className="font-medium text-[#4c6ee2] hover:text-black duration-200"
              >
                Sign in
              </button>
            </div> */}
          </div>

          <div className="flex flex-1 items-center justify-center px-10 w-full">
            <div className="w-full max-w-md space-y-5">
            <div className="flex items-center justify-center">
            <img
                src={require("../assets/1.png")}
                alt="logo"
                className="w-30 h-15 lg:hidden max-w-[75%]"
                onClick={() => window.location.href = process.env.REACT_APP_REDIRECT_URL}
              />
            </div>
              <div className="">
                <div className="flex flex-col space-y-2 text-center">
                  <h2 className="text-3xl md:text-4xl font-bold">Create Account</h2>
                  <p className="text-md md:text-xl">
                    Sign up now to get started with your account
                  </p>
                </div>
              </div>

              <form onSubmit={handleSubmit} className="flex flex-col max-w-md space-y-5">
                <div>
                  <input
                    type="text"
                    placeholder="Full Name"
                    name="fullname"
                    value={formData.fullname}
                    onChange={handleInputChange}
                    className="flex w-full px-3 py-2 md:px-4 md:py-3 border-2 border-black rounded-lg font-medium placeholder:font-normal"
                  />
                  {errors.fullname && <p className="text-red-500 mb-0 mt-1">{errors.fullname}</p>}
                </div>
                <div>
                  <input
                    type="email"
                    placeholder="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className="flex w-full px-3 py-2 md:px-4 md:py-3 border-2 border-black rounded-lg font-medium placeholder:font-normal"
                  />
                  {errors.email && <p className="text-red-500 mb-0 mt-1">{errors.email}</p>}
                </div>
                <div>
                  <input
                    type="password"
                    placeholder="Password"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    className="flex w-full px-3 py-2 md:px-4 md:py-3 border-2 border-black rounded-lg font-medium placeholder:font-normal"
                  />
                  {errors.password && <p className="text-red-500 mb-0 mt-1">{errors.password}</p>}
                </div>
                <button
                  type="submit"
                  disabled={loading}
                  className="flex items-center justify-center flex-none px-3 py-2 md:px-4 md:py-3 border-2 rounded-lg font-medium border-black bg-black text-white hover:bg-[#4c6ee2] hover:border-[#4c6ee2] duration-700"
                >
                  {loading ? (
                    <><Spinner aria-label="Loading..." /> <span className="pl-3">Loading...</span></>
                  ) : (
                    "Sign Up"
                  )}
                </button>
                {/* <div className="flex justify-center items-center">
                  <span className="w-full border border-black"></span>
                  <span className="px-4">Or</span>
                  <span className="w-full border border-black"></span>
                </div>
                <button
                  type="button"
                  className="flex items-center justify-center w-full px-3 py-2 md:px-4 md:py-3 border-2 rounded-lg font-medium border-black relative"
                >
                  
                  <span className="absolute left-4">
                    <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path fill="#EA4335" d="M5.26620003,9.76452941 C6.19878754,6.93863203 8.85444915,4.90909091 12,4.90909091 C13.6909091,4.90909091 15.2181818,5.50909091 16.4181818,6.49090909 L19.9090909,3 C17.7818182,1.14545455 15.0545455,0 12,0 C7.27006974,0 3.1977497,2.69829785 1.23999023,6.65002441 L5.26620003,9.76452941 Z"/>
                      <path fill="#34A853" d="M16.0407269,18.0125889 C14.9509167,18.7163016 13.5660892,19.0909091 12,19.0909091 C8.86648613,19.0909091 6.21911939,17.076871 5.27698177,14.2678769 L1.23746264,17.3349879 C3.19279051,21.2936293 7.26500293,24 12,24 C14.9328362,24 17.7353462,22.9573905 19.834192,20.9995801 L16.0407269,18.0125889 Z"/>
                      <path fill="#4A90E2" d="M19.834192,20.9995801 C22.0291676,18.9520994 23.4545455,15.903663 23.4545455,12 C23.4545455,11.2909091 23.3454545,10.5272727 23.1818182,9.81818182 L12,9.81818182 L12,14.4545455 L18.4363636,14.4545455 C18.1187732,16.013626 17.2662994,17.2212117 16.0407269,18.0125889 L19.834192,20.9995801 Z"/>
                      <path fill="#FBBC05" d="M5.27698177,14.2678769 C5.03832634,13.556323 4.90909091,12.7937589 4.90909091,12 C4.90909091,11.2182781 5.03443647,10.4668121 5.26620003,9.76452941 L1.23999023,6.65002441 C0.43658717,8.26043162 0,10.0753848 0,12 C0,13.9195484 0.444780743,15.7301709 1.23746264,17.3349879 L5.27698177,14.2678769 Z"/>
                    </svg>
                  </span>
                  <span>Sign up with Google</span>
                </button> */}

                
              </form>
              <div className="flex items-center space-x-1 mb-4 justify-center lg:hidden">
              <span>Already a member? </span>
              <button
                onClick={() => navigate("/login")}
                className="underline font-medium text-[#4c6ee2] hover:text-black duration-200 cursor-pointer"
              >
                Sign in
              </button>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
